.login {
  padding: .8rem;
}
.login-header h3 {
  font-size: 0.69333333rem;
  color: #37383d;
  font-weight: 600;
}
.login-header p {
  font-size: 0.37333333rem;
  color: #a0a4a3;
  margin-top: .1rem;
}
.login-form {
  margin-top: .8rem;
  padding: 0 .1rem;
}
.login-input {
  width: 100%;
  display: flex;
  overflow: hidden;
  margin-bottom: .6rem;
}
.login-input .item {
  flex: 1;
  border-radius: .6rem;
  overflow: hidden;
}
.login-input .code {
  margin-left: .4rem;
  width: 3rem;
}
.login-input input {
  font-size: 0.37333333rem;
  height: 1.18rem;
  border: none;
  box-sizing: border-box;
  padding: .1rem .8rem;
  width: 100%;
  background: #f9f9f9;
}
.login-input a {
  font-size: 0.37333333rem;
  height: 1.18rem;
  border-radius: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5ec2b5;
  color: #FFF;
}
.login-input a.opacity {
  opacity: .6;
}
.login-submit a {
  font-size: 0.37333333rem;
  height: 1.18rem;
  width: 100%;
  border-radius: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5ec2b5;
  color: #FFF;
}
.login-submit a.opacity {
  opacity: .6;
}
.login-agreement {
  color: #a0a4a3;
  font-size: 0.32rem;
  margin-top: .4rem;
  text-align: center;
}
.login-agreement a {
  color: #5ec2b5;
  font-size: 0.32rem;
}

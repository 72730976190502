.login                      {padding: .8rem;
    &-header                {
        h3                  {.fs(26);color: #37383d;font-weight: 600;}
        p                   {.fs(14);color: #a0a4a3;margin-top: .1rem;}
    }
    &-form                  {margin-top: .8rem;padding: 0 .1rem;}
    &-input                 {width: 100%;display: flex;overflow: hidden;margin-bottom: .6rem;
        .item               {flex: 1;border-radius: .6rem;overflow: hidden;}
        .code               {margin-left: .4rem;width: 3rem;}
        input               {.fs(14);height: 1.18rem;border: none;box-sizing: border-box;padding: .1rem .8rem;width: 100%;background: #f9f9f9;}
        a                   {.fs(14);height: 1.18rem;border-radius: .6rem;display: flex;align-items: center;justify-content: center;background: #5ec2b5;color: #FFF;
            &.opacity       {opacity: .6;}
        }
    }
    &-submit                {
        a                   {.fs(14);height: 1.18rem;width: 100%;border-radius: .6rem;display: flex;align-items: center;justify-content: center;background: #5ec2b5;color: #FFF;
            &.opacity       {opacity: .6;}
        }
    }
    &-agreement             {color: #a0a4a3;.fs(12);margin-top: .4rem;text-align: center;
        a                   {color: #5ec2b5;.fs(12);}
    }
}